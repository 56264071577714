import React, { FC } from 'react'
import { AboutBank } from '@features'
import { PageLayout } from '@widgets'
import { TPageData } from './types'

export const AboutBankPage: FC<TPageData> = ({ headerFooter, tab, ...pageData }) => (
  <PageLayout {...headerFooter} active={tab}>
    <AboutBank {...pageData} />
  </PageLayout>
)
